import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [{
    path: '/',
    redirect: '/index'
},
    {
        path: '/index',
        name: 'Index',
        component: () => import('@/view/index.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/view/login.vue')
    },
    {
        path: '/hello',
        name: 'HelloWorld',
        component: () => import('@/components/HelloWorld.vue')
    },
    {
        path: '/task',
        name: 'Task',
        component: () => import('@/view/task.vue')
    },
    {
        path: '/more-detail',
        name: 'moreDetail',
        component: () => import('@/view/task-more-detail.vue')
    },
    {
        path: '/company',
        name: 'company',
        component: () => import('@/view/company.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to,from,next) =>{
    const token = sessionStorage.getItem('token')
    console.log('token:',token)
    console.log(to.name, from.name)
    if(to.name !='Login' && !token){
        // next()
        next({
            name:'Login'
        })  //没去登录页，还没token，跳转去登录页
    } else {
        next()
    }
})


export default router
