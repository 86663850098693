<template xmlns="http://www.w3.org/1999/html">
  <div class="task-detail-container">
    <div class="task-header">
      <span class="task-header-left">工单详情</span>
      <el-icon class="task-header-close" @click="close()">
        <Close/>
      </el-icon>
    </div>
    <div class="task-form">
      <div class="task-item">
        <div class="task-item-label">工单标题</div>
        <el-input v-model="info.title" placeholder="" readonly="true"/>
      </div>
      <div class="task-item" style="width: 40%">
        <div class="task-item-label">工单执行人</div>
        <el-input v-model="info.actionUser.username" placeholder="" readonly="true"/>
      </div>
      <div class="task-item" style="width: 40%">
        <div class="task-item-label">工单类型</div>
        <el-input v-model="typeName" placeholder="" readonly="true"/>
      </div>
      <div class="task-item">
        <div class="task-item-label">地址</div>
        <el-input v-model="info.address" placeholder="" readonly="true"/>
      </div>
      <div class="task-item" style="width: 40%">
        <div class="task-item-label">创建时间</div>
        <el-input v-model="createTime" placeholder="" readonly="true"/>
      </div>
      <div v-if="info.type!=2">
        <div class="task-item" v-for="value in other">

          <div class="task-item-label">
            <el-checkbox v-model="value.is_check" size="large" />
            {{value.title}}
            <!-- <el-radio-group v-model="value.check_title" size="small">
              <el-radio-button label="异常" />
              <el-radio-button label="正常" />
            </el-radio-group> -->
          </div>
          <!-- <el-input v-show="!value.is_check" v-model="value.content" placeholder="" readonly="true" type="textarea" rows="3"/> -->
        </div>
      </div>
      <div class="task-item">
        <div class="task-item-label">工单描述</div>
        <el-input v-model="info.description" placeholder="" readonly="true" type="textarea" rows="5"/>
      </div>
      <div class="task-item">
        <div class="task-item-label">处理照片</div>
        <div v-for="(item,index) in covers" class="pics">
          <el-image style="width: 100px; height: 100px"
                    :src="item"
                    :fit="'cover'"
                    :preview-src-list="covers"
                    :initial-index="index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, onMounted, toRefs, defineEmits,ref} from 'vue'
import moment from "moment"

const props = defineProps({
  info: Object,
})

const { info} = toRefs(props);
// const typeName = ref('')

const emiter = defineEmits(["on-change"])
onMounted(() => {
  // getTaskList()
  // getSiteList()
})


const other = computed(() => {
  if (!info.value.other) {
    return []
  } else {
    let _other = JSON.parse(info.value.other)
    _other.forEach((value, index) => {
      _other[index]['check_title'] = value.is_check?'正常':'异常'
    })
    console.log("other:",_other)
    return _other
  }
})

const typeName = computed(() => {
  if (info.value.type == 1) {
    return '养护'
  } else if (info.value.type == 2) {
    return '维修'
  } else if (info.value.type == 3) {
    return '巡检'
  } else {
    return ''
  }
})

const covers = computed(() => {
  return info.value.pics.split(",")
})

const createTime = computed(() => {
  return moment(info.value.CreatedAt).format("YYYY-MM-DD HH:mm:ss")
})

const close = () => {
  emiter("on-change", false)
}

</script>

<style scoped>

.back {
  position: absolute;
  /*bottom: 5px;*/
  right: 30px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.task-detail-container {
  background-color: white;
  overflow-y: auto;
  /*border-left:2px solid #4DA3F3;*/
  /*border-radius: 5px 0 0 5px;*/
}

.task-header {
  position: relative;
  left: 0;
  top: 0;
  width: calc(100% - 40px);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid #F0F1F2;
}

.task-form {
  padding: 20px;

}

.task-item {
  padding-bottom: 20px;
}

.task-item-label {
  padding-bottom: 10px;
}

.pics {
  display: inline-block;
  margin-right: 20px;
}

.task-header-close:hover {
  cursor: pointer;
}

</style>
